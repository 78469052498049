var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.pageIndexApiDataIsLoading)?_c('div',[_c('company-licence-component'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 m-b-0"},[_c('router-link',{staticClass:"btn btn-white m-r-5",attrs:{"to":"/admin"}},[_c('i',{staticClass:"fa fa-cogs fa-fw fa-lg"})]),_c('router-link',{staticClass:"btn btn-white m-r-5",attrs:{"to":"/admin/products"}},[_c('i',{staticClass:"fa fa-cubes fa-fw fa-lg"}),_vm._v(" Gestion des Articles")]),_c('router-link',{staticClass:"btn btn-white m-r-5 text-orange",attrs:{"to":"/admin/clients"}},[_c('i',{staticClass:"fa fa-users fa-fw fa-lg text-orange"}),_vm._v(" Gestion des Clients")]),_c('router-link',{staticClass:"btn btn-white m-r-5",attrs:{"to":"/admin/exports"}},[_c('i',{staticClass:"fa fa-file-download fa-fw fa-lg"}),_vm._v(" Export des Données")])],1)]),_c('hr',{staticClass:"bg-grey-lighter"}),_c('div',{staticClass:"row m-b-5"},[_c('div',{staticClass:"col-md-12 m-b-0"},[_c('router-link',{staticClass:"btn btn-white m-r-5 text-orange",attrs:{"to":"/admin/clients"}},[_c('i',{staticClass:"fa fa-users fa-fw fa-lg text-orange"})]),_c('router-link',{staticClass:"btn btn-white m-r-5",attrs:{"to":"/admin/clients/import"}},[_c('i',{staticClass:"fa fa-download fa-fw fa-lg"}),_vm._v(" Importer")])],1)]),(_vm.pageIndexApiData != null)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"panel panel-"},[_c('div',{staticClass:"panel-body"},[_c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.pageIndexApiData.clients,"lineNumbers":true,"search-options":{ enabled: true, placeholder: 'Recherche rapide'},"pagination-options":{
                                          enabled: true,
                                          position: 'bottom',
                                          nextLabel: 'Suiv.',
                                          prevLabel: 'Préc.',
                                          rowsPerPageLabel: 'Entrées par page',
                                          ofLabel: 'de',
                                          pageLabel: 'page',
                                          allLabel: 'Tous',

                                       },"selectOptions":{
                                              enabled: false,
                                              selectOnCheckboxOnly: true,
                                              selectionInfoClass: 'alert alert-green m-b-0',
                                              selectionText: 'Entrées sélectionnées',
                                              clearSelectionText: 'Annuler la sélection',
                                          }},on:{"on-row-click":_vm.onRowClick}},[_c('div',{staticStyle:{"margin":"-2px 0"},attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',{staticClass:"btn btn-xs btn-primary m-r-5"},[_vm._v("Action 1")]),_c('button',{staticClass:"btn btn-xs btn-grey"},[_vm._v("Action 2")])])])],1)])])])]),_c('b-modal',{attrs:{"centered":"","hide-header":"","hide-footer":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":"","id":"clientModal","title":"Modal Title","size":"xl"},on:{"show":()=>{},"hidden":()=>{}}},[_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-12 text-right"},[_c('a',{staticClass:"btn btn-danger",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$bvModal.hide('clientModal')}}},[_c('i',{staticClass:"fa fa-times fa-fw fa-lg"})])])]),_c('client-account-component',{attrs:{"itemId":_vm.selectedClientId}})],1)],1):_vm._e()],1):_c('div',{staticClass:"panel-loader"},[_c('span',{staticClass:"spinner-small"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }