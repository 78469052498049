<template>
  <div>
    <div v-if="!pageIndexApiDataIsLoading">
      <company-licence-component/>

      <div class="row">
        <div class="col-md-12 m-b-0">
          <router-link class="btn btn-white m-r-5" to="/admin"><i class="fa fa-cogs fa-fw fa-lg"></i></router-link>
          <router-link class="btn btn-white m-r-5" to="/admin/products"><i class="fa fa-cubes fa-fw fa-lg"></i> Gestion des Articles</router-link>
          <router-link class="btn btn-white m-r-5 text-orange" to="/admin/clients"><i class="fa fa-users fa-fw fa-lg text-orange"></i> Gestion des Clients</router-link>
          <router-link class="btn btn-white m-r-5" to="/admin/exports"><i class="fa fa-file-download fa-fw fa-lg"></i> Export des Données</router-link>
        </div>
      </div>

      <!--<h1 class="page-header">Playground  <small class="lead">.</small></h1>-->
      <hr class="bg-grey-lighter" />

      <div class="row m-b-5">
        <div class="col-md-12 m-b-0">
          <router-link class="btn btn-white m-r-5 text-orange" to="/admin/clients"><i class="fa fa-users fa-fw fa-lg text-orange"></i></router-link>
          <router-link class="btn btn-white m-r-5" to="/admin/clients/import"><i class="fa fa-download fa-fw fa-lg"></i> Importer</router-link>
        </div>
      </div>

      <div v-if="pageIndexApiData != null">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="panel panel-">
              <!--
              <div class="panel-heading">
                 <h1 class="panel-title">Liste des clients</h1>
                 <div class="panel-heading-btn">
                   <a class="btn btn-xs btn-icon btn-circle btn-success"><i class="fa fa-redo"></i></a>
                </div>
              </div>
              -->
              <div class="panel-body">
                <div>
                  <vue-good-table
                      :columns="columns"
                      :rows="pageIndexApiData.clients"
                      @on-row-click="onRowClick"
                      :lineNumbers="true"
                      :search-options="{ enabled: true, placeholder: 'Recherche rapide'}"
                      :pagination-options="{
                                            enabled: true,
                                            position: 'bottom',
                                            nextLabel: 'Suiv.',
                                            prevLabel: 'Préc.',
                                            rowsPerPageLabel: 'Entrées par page',
                                            ofLabel: 'de',
                                            pageLabel: 'page',
                                            allLabel: 'Tous',

                                         }"
                      :selectOptions="{
                                                enabled: false,
                                                selectOnCheckboxOnly: true,
                                                selectionInfoClass: 'alert alert-green m-b-0',
                                                selectionText: 'Entrées sélectionnées',
                                                clearSelectionText: 'Annuler la sélection',
                                            }"
                  >
                    <div slot="selected-row-actions" style="margin: -2px 0;">
                      <button class="btn btn-xs btn-primary m-r-5">Action 1</button>
                      <button class="btn btn-xs btn-grey">Action 2</button>
                    </div>
                    <!--
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'image'">
                            <img v-bind:src="props.row.image" class="rounded" width="24" style="margin: -3px" />
                        </span>
                                                    <span v-else>
                            {{props.formattedRow[props.column.field]}}
                        </span>
                    </template>
                    -->
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Dialogs -->
        <b-modal
            centered
            hide-header
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            id="clientModal"
            title="Modal Title"
            size="xl"
            @show="()=>{}"
            @hidden="()=>{}">

          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-danger" @click="$bvModal.hide('clientModal')"><i
                  class="fa fa-times fa-fw fa-lg"></i></a>
            </p>
          </div>

          <client-account-component :itemId="selectedClientId"/>

          <!--
          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-comment fa-fw fa-lg"></i></a>
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-cogs fa-fw fa-lg"></i></a>
            </p>
          </div>
          -->
        </b-modal>
      </div>
    </div>
    <div class="panel-loader" v-else><span class="spinner-small"></span></div>
  </div>
</template>


<script>
import pageApi from '../../store/api/admin/clients-dashboard-page-api'
import LocalStorage from "../../store/local-storage";
import CompanyLicenceComponent from '../../components/app/base/CompanyLicenceComponent.vue'
import ClientAccountComponent from "@/components/app/ClientAccountComponent";

export default {
  name: 'clients-dashboard-page-component',
  components: {
    ClientAccountComponent,
    CompanyLicenceComponent,
  },
  props: {},
  watch: {},
  computed: {},
  methods: {
    //Initialization Methods
    loadPageScripts() {
    },

    onRowClick(params) {
      // params.row - row object
      // params.pageIndex - index of this row on the current page.
      // params.selected - if selection is enabled this argument indicates selected or not
      // params.event - click event
      this.selectedClientId = params.row.id;
      this.$bvModal.show('clientModal');
    },

    //Other Methods
    async getPageIndexApiData() {
      this.pageIndexApiDataIsLoading = true;
      this.pageIndexApiDataErrorMessage = null;
      try {
        const response = await pageApi.getIndexData();
        //console.log(response);
        this.pageIndexApiData = response.data;
        this.pageIndexApiDataIsLoading = false;
        this.pageIndexApiDataErrorMessage = null;
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
            this.pageIndexApiDataIsLoading = false;
            this.pageIndexApiDataErrorMessage = error.response.data.message;
            LocalStorage.clearSessionData();
            this.$router.push('login');
          }  else {
            this.pageIndexApiDataIsLoading = false;
            this.pageIndexApiDataErrorMessage = error.response.data.message;
            //
            this.$swal({
              icon: 'error',//'info','success','warning','error','question',
              toast: true,
              position: 'bottom-end',
              timer: 3000,
              showConfirmButton: false,
              title: 'HTTP ' + error.response.status,
              text: error.response.data.message,
            })
          }
        } else {
          this.pageIndexApiDataIsLoading = false;
          this.pageIndexApiDataErrorMessage = error.message;
          //
          this.$swal({
            icon: 'error',//'info','success','warning','error','question',
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            showConfirmButton: false,
            //title: error.message
            text: error.message,
          })
        }
      }
    },
  },
  data: function () {
    return {
      appName: window.appConfig.appName,
      appCurrency: window.appConfig.appCurrency,
      intervalId01: 0,

      selectedClientId: null,

      columns: [
        {
          label: 'Code Entité',
          field: 'entity_code',
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        },
        {
          label: 'Code Localité',
          field: 'location_code',
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        },
        {
          label: 'Code Client',
          field: 'erp_code',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          label: 'Désignation',
          field: 'name',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          label: 'Téléphone',
          field: 'phone',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          label: 'Type Client',
          field: 'group_name',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          label: 'Localité',
          field: 'location_name',
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        },
        {
          label: 'Sect. Activité',
          field: 'type_name',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          label: 'Zone',
          field: 'commercial_cluster_name',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          label: 'Secteur',
          field: 'commercial_sector_name',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          label: 'Segmentation',
          field: 'commercial_zone_name',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
      ],

      //API Data
      //
      pageIndexApiData: null,
      pageIndexApiDataIsLoading: false,
      pageIndexApiDataErrorMessage: null,
    };
  },
  created: function () {
    this.getPageIndexApiData();
  },
  mounted: function () {
    this.loadPageScripts();
  },
  beforeDestroy: function () {
  },
}
</script>
